import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import CollapsibleTable from "./Table";
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

function SearchNoticeComponent(){
    const [ data, setData ] = useState([]);
    const [ searchItem, setSearchItem ] = useState('');
    const [ feedback, setFeedBack ] = useState(false);
    const navigate = useNavigate();
  
    const handleNavigate = (link) =>{
      navigate(link);
    }
  
    const serverAPI = async () =>{
      await axios.get(`/api/contact/search/${searchItem}`)
        .then((response)=>{
          if(!response.data) return 
          setData(response.data);
          setFeedBack(true);
        })
        .catch((err)=>{
          console.log(err.message);
        })
    }
  
    const handleChange = async (e) =>{
      setFeedBack(false);
      setSearchItem(e.target.value);
    }
  
    const handleSearch = (e) =>{
      e.preventDefault();
      if(searchItem == '')return
      serverAPI();
    }
  
    return(
      <div className="row">
        {/* search component */}
        <Navbar expand="lg" className="bg-body-tertiary">
          <Container fluid>
            <Navbar.Collapse id="navbarScroll">
              <Form className="d-flex">
                <Form.Control
                  type="search"
                  placeholder="Search"
                  className="me-2"
                  aria-label="search"
                  onChange={handleChange}
                  aria-placeholder="contact email"
                />
                <Button variant="outline-warning" onClick={handleSearch}>Search</Button>
              </Form>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        {/* search result component */}
        <Card>
          <Card.Body>
            {feedback ? <CollapsibleTable notice={data} /> : ""}
            <Card.Title>Contact Management</Card.Title>
            <Card.Text>
                Welcome to the Contact Management Panel for Administrators. This interface allows you to efficiently manage users who have subscribed to our newsletter and those who have reached out through the website contact form. Please ensure you follow all data privacy and security protocols while handling user information. If you need any assistance, our support team is available to help. Thank you for your commitment to effective user engagement and management.
            </Card.Text>
            <Button 
                variant="warning" 
                onClick={()=>handleNavigate('/account/admin/newsletter')}>Send Newsletter</Button>&nbsp;&nbsp;&nbsp;
            <Button 
                variant="secondary" 
                onClick={()=>handleNavigate('/account/admin')}>Go To Dashboard</Button>
          </Card.Body>
        </Card>
      </div>
    )
}

const ContactManager = () => {
    const [ contactData, setContactData ] = useState([]);

    const contactAPI = async () =>{
      await axios.get(`/api/contact`)
        .then((response)=>{
          setContactData(response.data);
        })
        .catch((err)=>{
          console.log("Problem with network! Try again later.");
        })
    }

    useEffect(()=>{
      contactAPI();
    },[])

    return(
        <div>
            <SearchNoticeComponent />
            <CollapsibleTable notice={contactData} />
        </div>
    )
}

export default ContactManager;