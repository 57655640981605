import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/wlogo.svg';

function AppealForm() {
    const [ formData, setFormData ] = useState({
      name: '',
      email: '',
      phone: '',
      role: '',
      notice: '',
      vehicle: '',
      message: '',
      file: null
    })
    const [ feedback, setFeedback ] = useState("");
    const navigate = useNavigate();
  
    const handleChange = (e) =>{
      const { name, value } = e.target;
      setFormData({...formData, [name]: value});
    }

    const handleFileChange = (e) =>{
      setFormData({
        ...formData, 
        file: e.target.files[0] 
      });
    }
    
    const appealAPI = async () =>{
      axios.post(`/api/appeal`, formData)
        .then((response)=>{
            setFormData({
              name: '', email: '', phone: '', role: '', notice: '', vehicle: '', message: '', file: null 
            });
            setFeedback("Your Appeal has been submitted!")
            setTimeout(()=>{
              navigate('/')
            }, 9000)
        })
        .catch((err)=>{        
            setFeedback("Problem with network! Try again later.");
            setTimeout(()=>{
              setFeedback("");
            }, 15000)
        })
    }
    
    const handleSubmit = (e) =>{
      e.preventDefault();
      appealAPI();
    }
  
    return (
        <Form className="my-2 py-3 px-2 rounded form-container" style={{ background: '#31393C'}}>
         <div className='w-100 text-center' style={{ height: '8rem'}}>
                <img
                    className="img-fluid rounded d-block w-100 h-100"
                    loading='lazy'
                    src={Logo}
                    alt="Our company logo"
                />
          </div>
          {/* form title */}
          <div className='m-2 p-1 text-center'>
            <h3>Make Your Appeal</h3>
          </div>
        {/* name input */}
        <Form.Group className="mb-3" controlId="formBasicEmail" style={{ textAlign: 'left'}}>
          <Form.Label>Name:</Form.Label>
          <Form.Control type="text" name='name' onChange={handleChange} placeholder="Enter your name" />
        </Form.Group>
        {/* address input */}
        <Form.Group className="mb-3" controlId="formBasicEmail" style={{ textAlign: 'left'}}>
          <Form.Label>Address:</Form.Label>
          <Form.Control type="text" name='address' onChange={handleChange} placeholder="Enter your address" />
        </Form.Group>
        {/* role of user */}
        <Form.Group className='mb-3' style={{ textAlign: 'left'}}>
          <Form.Label>I am the:</Form.Label>
          <Form.Select aria-label="select role input" name='role' onChange={handleChange}>
            <option value="driver">driver</option>
            <option value="keeper">keeper</option>
            <option value="hirer">hirer</option>
          </Form.Select>
        </Form.Group>
        {/* phone input */}
        <Form.Group controlId="formBasicText" className="mb-3" style={{ textAlign: 'left'}}>
          <Form.Label>Phone:</Form.Label>
          <Form.Control type="text" name='phone' onChange={handleChange} placeholder="Enter your phone number" />
        </Form.Group>
        {/* Parking Charge Notice */}
        <Form.Group controlId="formBasicText" className="mb-3" style={{ textAlign: 'left'}}>
          <Form.Label>PCN Number:</Form.Label>
          <Form.Control type="text" name='PCNnumber' onChange={handleChange} placeholder="Enter your parking charge notice" />
        </Form.Group>
        {/* Vehicle Registration Plate */}
        <Form.Group controlId="formBasicText" className="mb-3" style={{ textAlign: 'left'}}>
          <Form.Label>Vehicle Number Plate:</Form.Label>
          <Form.Control type="text" name='VRNnumber' onChange={handleChange} placeholder="Enter your vehicle number plate" />
        </Form.Group>
        {/* message textarea */}
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1" style={{ textAlign: 'left'}}>
          <Form.Label>Reason:</Form.Label>
          <Form.Control as="textarea" name='message' onChange={handleChange} rows={3} placeholder='Enter your message'/>
        </Form.Group>
        {/* file input */}
        <Form.Group controlId="formFileMultiple" className="mb-3" style={{ textAlign: 'left'}}>
          <Form.Label>Choose File:</Form.Label>
          <Form.Control type="file" name='file' onChange={handleFileChange} multiple />
        </Form.Group>
        {/* user feedback */}
        <Form.Group className='text-center m-2'>
          <Form.Text className="text-warning" style={{
            fontSize: '1.25rem'
          }}>
            { feedback !== ""? feedback: ""}
          </Form.Text>
        </Form.Group>
        {/* submit contact btn */}
        <div className='text-center'>
          <Button variant="warning" type="submit" className='m-1 p-2 w-100 text-uppercase' onClick={handleSubmit}>
            Submit Appeal
          </Button>
        </div>
      </Form>
    );
}

export const Appeal = () => {
    return (
        <div className='hero' style={{ height: 'auto'}}>
            {/* contact content */}
            <div>
                <AppealForm />
            </div>
        </div>
    )
}