import { Button, Form } from 'react-bootstrap';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AuthData } from '../../auth/AuthWrapper';
import { validateEmail, fetchUser, validatePassword } from '../services/middleware';
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

export function LoginUser() {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [feedback, setFeedback] = useState('');
  const [isLogging, setIsLogging] = useState(false);
  const navigate = useNavigate();
  const { login } = AuthData();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const loginAPI = async () => {
    setIsLogging(true);
    try {
      await login(formData.email, formData.password);
      const user = await fetchUser();
      if (user) {
        setTimeout(() => {
          if(user.type == 'admin') navigate(`/account/admin`);
          else if (user.type === 'landlord') navigate(`/account/landlord`);
          else navigate(`/account/motorist`);
        }, 500);
      }
      setFormData({ email: '', password: '' }); // Clear form data only after successful login
    } catch (error) {
      setFeedback('Invalid email or password. Please try again.');
      setFormData({ email: '', password: '' }); // Clear form data on error
    } finally {
      setIsLogging(false); // Reset logging state
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.email === '' || formData.password === '') {
      setFeedback('Please enter your login details!');
      return;
    }
    setFeedback(''); // Clear feedback before starting the login process
    loginAPI();
  };

  return (
    <Form className="m-1 p-1 form-container" onSubmit={handleSubmit}>
      <div>
        <h3 className="m-1 p-2 text-center text-capitalize">log into account</h3>
      </div>
      {/* Email input */}
      <Form.Group className="mb-2" controlId="formBasicEmail">
        <Form.Label>Email:</Form.Label>
        <Form.Control
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="Enter your email"
          required
        />
        <Form.Text className="text-muted">
          We'll never share your email with anyone else.
        </Form.Text>
      </Form.Group>
      {/* Password input */}
      <Form.Group className="mb-2" controlId="formBasicPassword">
        <Form.Label>Password:</Form.Label>
        <Form.Control
          type="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          placeholder="Enter your password"
          required
        />
      </Form.Group>
      {/* Remember Password Checkbox */}
      <Form.Group className="mb-2" controlId="formBasicCheckbox">
        <Form.Check
          type="checkbox"
          label="Remember Password"
          className="text-warning"
          style={{ fontSize: '.75rem' }}
        />
      </Form.Group>
      {/* User Feedback */}
      <Form.Group className="text-center m-2">
        <Form.Text className="text-warning" style={{ fontSize: '1.25rem' }}>
          {isLogging ? <fetchSubLoader /> : <span>{feedback}</span>}
        </Form.Text>
      </Form.Group>
      {/* Login Button */}
      <div className="text-center">
        <Button
          variant="warning"
          className="w-100"
          type="submit"
          disabled={isLogging} // Disable button while logging in
        >
          {isLogging ? 'Logging in...' : 'Login'}
        </Button>
      </div>
    </Form>
  );
}

export function RegisterUser() {
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: '',
    type: ''
  });
  const [feedback, setFeedback] = useState('');
  const [msg, setMsg] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const IsFormValid = () =>{
    if(validateEmail(formData.email)) return false;
    return true;
  }

  const registerAPI = async () => {
    try {
      const response = await axios.post(`/api/user/register`, formData);
      setFormData({
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        password: '',
        type: ''
      });
      setFeedback("Account registration successful!");
      setTimeout(() => {
        navigate('/login');
      }, 3000);
    } catch (err) {
      if (err.response && err.response.status === 409) { // 409 Conflict - Email already exists
        setFeedback("This email is already registered. Please use a different email.");
      } else {
        setFeedback("Problem with network! Try again later.");
      }
      setTimeout(() => {
        setFeedback("");
      }, 5000);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if(!validatePassword(formData.password)){
      setMsg('password must have more than 8 letters');
      return 
    }
    registerAPI();
  };

  return (
    <Form className='m-1 p-2 form-container' onSubmit={handleSubmit}>
      <div>
        <h3 className='m-1 p-2 text-center text-capitalize'>register your account</h3>
      </div>
      {/* Firstname input */}
      <Form.Group className='mb-3' controlId='formBasicFirst'>
        <Form.Label>First Name:</Form.Label>
        <Form.Control
          type='text'
          name='firstname'
          value={formData.firstname}
          onChange={handleChange}
          placeholder='Enter your firstname'
          required
        />
      </Form.Group>
      {/* Lastname input */}
      <Form.Group className='mb-3' controlId='formBasicLast'>
        <Form.Label>Last Name:</Form.Label>
        <Form.Control
          type='text'
          name='lastname'
          value={formData.lastname}
          onChange={handleChange}
          placeholder='Enter your lastname'
          required
        />
      </Form.Group>
      {/* Phone input */}
      <Form.Group className='mb-3' controlId='formBasicPhone'>
        <Form.Label>Phone:</Form.Label>
        <Form.Control
          type='text'
          name='phone'
          value={formData.phone}
          onChange={handleChange}
          placeholder='Enter your phone number'
          required
        />
      </Form.Group>
      {/* Email input */}
      <Form.Group className='mb-3' controlId='formBasicEmail'>
        <Form.Label>Email:</Form.Label>
        <Form.Control
          type='email'
          name='email'
          value={formData.email}
          onChange={handleChange}
          placeholder='Enter your email address'
          required
        />
      </Form.Group>
      {/* User type input */}
      <Form.Group className='mb-3' controlId='formBasicSelect'>
        <Form.Label>Type:</Form.Label>
        <Form.Select
          aria-label='select role input'
          name='type'
          value={formData.type}
          onChange={handleChange}
          required
        >
          <option value=''>...please choose user type...</option>
          <option value='landlord'>Landlord (i.e., car park owner)</option>
          <option value='client'>Client (i.e., PCN debt recovery)</option>
        </Form.Select>
      </Form.Group>
      {/* Password input */}
      <Form.Group className='mb-3' controlId='formBasicPassword'>
        <Form.Label>Password:</Form.Label>
        <Form.Control
          type='password'
          name='password'
          value={formData.password}
          onChange={handleChange}
          placeholder='Enter your password'
          required
        />
      </Form.Group>
      {/* Feedback */}
      <Form.Group className='text-center m-2'>
        <Form.Text className='text-warning' style={{ fontSize: '1.25rem' }}>
          {feedback}
        </Form.Text>
      </Form.Group>
      {/* Submit button */}
      <div className='text-center'>
        <Button variant='warning' className='w-100' type='submit' disabled={IsFormValid()}>
          Submit
        </Button>
        <Form.Text className='text-muted'>
          {msg && <p className='text-warning text-center'>{msg}</p>}
        </Form.Text>
      </div>
    </Form>
  );
}