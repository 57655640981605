import { useNavigate } from 'react-router-dom';
import wlogo from './../assets/wlogo.svg';

const Hero = () => {
    const navigate = useNavigate();

    return (
      <section id="hero" className="hero">
        <div className="shape"></div>
        <div className="container">
          <div className="row mt-5 justify-content-center">
            <div className="col-12 col-md-6">
                <div className="logo-container">
                    <img
                        src={wlogo}
                        alt="Ayopark logo"
                        style={{ height: '200px', maxWidth: '300px', color: '#fff' }}
                    />
                </div>
                <div className="mt-4 text-center">
                    <h2 className="fw-bold">Unlock the Potential of Your Car Park</h2>
                    <p className="lh-lg">
                    Maximize your car park's revenue potential with our innovative solutions, generating up to £50 profit share per paid parking charge.
                    </p>
                    <button
                        type='button'
                        onClick={()=>(navigate('/contact'))}
                        className="btn btn-dark py-2 px-4 mt-3">
                        Get Started Today
                    </button>
                </div>
            </div>
          </div>
        </div>
      </section>
    );
};

export default Hero;