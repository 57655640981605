import { Link, Route, Routes } from 'react-router-dom'
import { AuthData } from '../../auth/AuthWrapper'
import { nav } from './navigation'
import ErrorPage from '../../ErrorPage'

export const RenderRoutes = () => {
    const { user } = AuthData();

    return (
        <Routes>
            {
                nav.map((value, index) => {
                    if (value.isPrivate && user.isAuthenticated) {
                        return <Route key={index} path={value.path} element={value.element} />;
                    } else if (!value.isPrivate) {
                        return <Route key={index} path={value.path} element={value.element} />;
                    } else {
                        // If the route is private and the user is not authenticated, we can redirect to a login page or similar
                        return <Route key={index} path={value.path} element={<ErrorPage />} />;
                    }
                })
            }
            {/* Catch-all route for unmatched paths */}
            <Route path="*" element={<ErrorPage />} />
        </Routes>
    );
};

export const RenderMenu = () => {

    const {user, logout} = AuthData();
    const scrollToTop = () => {
        window.scrollTo(0, 0);
    }

    const MenuItem = ({value}) => {
        return (
            <li>
                <Link to={value.path}
                    onClick={scrollToTop} 
                    className="d-flex justify-content-left align-items-center text-reset sidebar-item" 
                    style={{
                        borderBottom: '.2rem solid #000',
                        margin: '1rem auto',
                        padding: '1rem .5rem',
                        cursor: 'pointer'
                    }}>
                    <i class={`pi ${value.img}`} style={{
                        fontSize: '1.2rem',
                        marginLeft: '.5rem',
                        marginRight: '.5rem'
                    }}></i>
                    <span>
                        {value.name}
                    </span> 
                </Link>
            </li>
        )
    }

    return (
        <>
            <nav>
                {
                    nav.map((value, index) => {
                        if (!value.isPrivate && value.isMenu) {
                            return (
                                <MenuItem key={index} value={value} />
                            )
                        } else if (user.isAuthenticated && value.isMenu) {
                            return (
                                <MenuItem key={index} value={value} />
                            )
                        } else return false
                    })
                }
                <div className='mb-5'>
                    {user.isAuthenticated ? <div>
                        <i class="pi pi-sign-out" style={{
                            fontSize: '1.2rem'
                        }}></i> 
                        <Link to='#' 
                            className="d-flex justify-content-left align-items-center text-reset" 
                            onClick={logout}>
                            <i class="pi pi-sign-in" style={{
                                fontSize: '1.2rem',
                                marginLeft: '.5rem',
                                marginRight: '.5rem',
                                color: 'inherit'
                            }}></i>
                            <span>
                                Logout
                            </span> 
                        </Link>
                    </div> : <div> 
                        <Link to={'login'}
                            onClick={scrollToTop} 
                            className="d-flex justify-content-left align-items-center text-reset">
                            <i class="pi pi-sign-in" style={{
                                fontSize: '1.2rem',
                                marginLeft: '.5rem',
                                marginRight: '.5rem',
                                color: 'inherit'
                            }}></i>
                            <span>
                                Login
                            </span> 
                        </Link> 
                    </div>}
                </div>
            </nav>
        </>
    )

}