import Logo from './assets/wlogo.svg';

const ErrorPage = () => {
    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <div className='w-100 text-center' style={{ height: '10rem'}}>
                <img
                    className="img-fluid rounded d-block w-100 h-100"
                    loading='lazy'
                    src={Logo}
                    alt="Our company logo"
                />
            </div>
            <h1>Oops!</h1>
            <p>Something went wrong.</p>
            <p>404 - Page Not Found</p>
        </div>
    );
};

export default ErrorPage;