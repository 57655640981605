import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { RenderMenu} from './RenderNavigation';
import { AuthData } from '../../auth/AuthWrapper';

function Sidebar() {
  const [show, setShow] = useState(false);
  const [pageWidth, setPageWidth] = useState(0);
  const {user, logout} = AuthData();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();

  const getPageWidth = async () =>{
    let width = await window.innerWidth;
    return setPageWidth(width);
  }

  const navigateTo = (link) =>{
    navigate(link);
  }

  useEffect(()=>{
    getPageWidth();
  },[pageWidth])

  return (
    <div className='d-flex justify-content-between align-items-center' style={{
      width: '100vw',
      paddingBottom: '.5rem',
      borderBottom: '.125rem solid #fff'
    }}>
      <div className='d-flex align-items-center' style={{ flex: '.9'}}>
        {pageWidth < 720? (<div className='d-flex align-items-center'>
          <i className="pi pi-bars"
            onClick={handleShow} 
            style={{ fontSize: '2.2rem', margin:'.2rem' }}>
          </i>
          <a href='/'>
            <h3 className='m-1 text-warning'>AYO<span className='text-light'>PARK</span></h3>
          </a>
        </div>): (<div className='d-flex align-items-center justify-content-between' style={{ flex: '.8'}}>
          <a href='/'>
            <h3 className='m-1 text-warning'>AYO<span className='text-light'>PARK</span></h3>
          </a>
          <div className='d-flex justify-content-center align-items-center'>
            <Button 
              variant="light" 
              onClick={()=>navigateTo('/')} 
              className='py-1 px-3 mx-2'>Home</Button>{' '}
            <Button 
              variant="light" 
              onClick={()=>navigateTo('/about')}
              className='py-1 px-3 mx-2'>FQA</Button>{' '}
            <Button 
              variant="light" 
              onClick={()=>navigateTo('/appeal')}
              className='py-1 px-3 mx-2'>Appeal</Button>{' '}
            <Button 
              variant="light"
              onClick={()=>navigateTo('/contact')}className='py-1 px-3 mx-2'>Contact</Button>{' '}
          </div>
        </div>)}
      </div>
      <div className='d-flex m-1 p-2 align-items-center'>
        {/* login/logout button */}
        <div className='m-2'>
        {user.isAuthenticated ? <Link 
            to={'/'} 
            onClick={logout}
            className='text-reset text-light'>
              Logout
          </Link> : <Link 
            to={'/login'}
            className='text-reset text-light'>
              Login
          </Link>}
        </div>
        <Link to={'/payment'} 
          className='btn bg-warning m-1 rounded-5'> Pay Charge</Link>
      </div>

      <Offcanvas show={show} onHide={handleClose} style={{
        width: '30vw'
      }}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title style={{
                height: '8rem'
              }}>
            <img
              className="img-fluid rounded d-block w-100 h-100"
              loading='lazy'
              src={`/logo.svg`}
              alt="Ayopark Logo"
            />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='d-flex flex-column'>
          <RenderMenu />
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export const RenderHeader = () => {

    return (
      <>
        <Sidebar />
      </>
    )
}