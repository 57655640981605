import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import Wlogo from '../../assets/wlogo.svg';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

export const CheckoutForm = () => {
    const [ formData, setFormData ] = useState({
        name: '',
        address: '',
        amount: 0,
        phone: '',
        email: '',
        PCNNumber: '',
        VRPNumber: ''
    });
    const stripe = useStripe();
    const elements = useElements();
    const [ feedback, setFeedback ] = useState("");

    const handleChange = (e) =>{
        const { name, value } = e.target;
        setFormData({...formData, [name]: value});
    }

    const paymentAPI = async () =>{
        if (!stripe || !elements) {
            setFeedback("Invalid Payment Details!");
            return;
        }
        const cardElement = elements.getElement(CardElement);

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: formData,
        });

        if (error) {
            setFeedback("Problem with network! Try again later.");
        } else {
            // Send paymentMethod.id to your server
            const response = await axios.post('/api/payment', {
                paymentMethodId: paymentMethod.id,
                amount: formData
            });
            setFeedback("Payment was successful!");
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        paymentAPI();
    };

    return (
        <div className="container d-flex justify-content-center align-items-center m-2 p-2" style={{
            width: '100vw'
        }}>
            <Form className="m-1 p-2">
                {/* form title */}
                <div className="form-group m-2 text-center">
                  <img
                    className="img-fluid rounded"
                    loading='lazy'
                    src={Wlogo}
                    alt="Ayopark Logo"
                    style={{
                      height: '10rem'
                    }}
                  />
                  <h4 className='text-center m-1 p-2'>Parking Charge Notice (PCN) Payment</h4>
                </div>
                {/* name input */}
                <Form.Group controlId="formBasicText" className="mb-3">
                    <Form.Label>Name:</Form.Label>
                    <Form.Control type="text" name='name' onChange={handleChange} placeholder="Enter client name" />
                </Form.Group>
                {/* address input */}
                <Form.Group controlId="formBasicAddress" className="mb-3">
                    <Form.Label>Address:</Form.Label>
                    <Form.Control type="text" name='address' onChange={handleChange} placeholder="Enter client address" />
                </Form.Group>
                {/* amount input */}
                <Form.Group controlId="formBasicAmount" className="mb-3">
                    <Form.Label>Amount:</Form.Label>
                    <Form.Control type="number" name='amount' onChange={handleChange} placeholder="Enter PCN amount" />
                </Form.Group>
                {/* phone input */}
                <Form.Group controlId="formBasicPhoneTxt" className="mb-3">
                    <Form.Label>Phone:</Form.Label>
                    <Form.Control type="number" name='amount' onChange={handleChange} placeholder="Enter your phone number" />
                </Form.Group>
                {/* email input */}
                <Form.Group controlId="formBasicEmailTxt" className="mb-3">
                    <Form.Label>Email:</Form.Label>
                    <Form.Control type="number" name='amount' onChange={handleChange} placeholder="Enter you email address" />
                </Form.Group>
                {/*  PCN Number */}
                <Form.Group controlId="formBasicPCNText" className="mb-3">
                    <Form.Label>PCN Number:</Form.Label>
                    <Form.Control type="text" name='PCNNumber' onChange={handleChange} placeholder="Enter parking charge notice number" />
                </Form.Group>
                {/*  Vehicle Registration */}
                <Form.Group controlId="formBasicVRPText" className="mb-3">
                    <Form.Label>Vehicle Registration:</Form.Label>
                    <Form.Control type="text" name='VRPNumber' onChange={handleChange} placeholder="Enter your vehicle registration number" />
                </Form.Group>
                {/*  Card Information */}
                <Form.Group controlId="formBasicCIText" className="mb-3">
                    <Form.Label>Card Details:</Form.Label>
                    <CardElement options={{ hidePostalCode: true }} />
                </Form.Group>
                {/* user feedback */}
                <Form.Group className='text-center m-2'>
                    <Form.Text className="text-warning" style={{
                    fontSize: '1.25rem'
                    }}>
                    { feedback !== ""? feedback: ""}
                    </Form.Text>
                </Form.Group>
                {/* submit contact btn */}
                <div className='text-center'>
                    <Button variant="warning" type="submit" className='w-100' onClick={handleSubmit}>
                        PAY
                    </Button>
                </div>
                <div className='text-center'>
                    <p className='m-2'>Please read AYOPARK's Privacy Policy to understand how we use your personal information</p>
                </div>
            </Form>
        </div>
    );
};

const Payment = () => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm />
    </Elements>
  );
}

export default Payment;