import Logo from '../../assets/wlogo.svg';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { validateEmail } from '../services/middleware';
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

const ContactForm = () => {
    const [ formData, setFormData ] = useState({
      name: '',
      email: '',
      phone: '',
      message: '',
      file: null
    })
    const [ feedback, setFeedback ] = useState("");
    const navigate = useNavigate();
  
    const handleChange = (e) =>{
      const { name, value } = e.target;
      setFormData({
        ...formData, 
        [name]: value
      });
    }

    const handleFileChange = (e) =>{
      setFormData({
        ...formData, 
        file: e.target.files[0] 
      });
    }
    
    const IsFormValid = () =>{
      if(validateEmail(formData.email)) return false;
      return true;
    }
    
    const handleSubmit = async (e) =>{
      e.preventDefault();
      try {
          const response = await axios.post(`/api/contact`, formData, {
              headers: {
                  'Content-Type': 'multipart/form-data',
              },
          });
          console.log(response);
          setFeedback("Thank you for contacting us.");
          // Reset form fields
          setFormData({
            name: '',
            email: '',
            phone: '',
            message: '',
            file: null
          });
      } catch (error) {
          setFeedback('Error submitting the form. Please try again.');
      }
    }
  
    return (
        <Form 
          className="px-3 py-2 form-container rounded" 
          style={{background: "#2F343A"}}>
          {/* form title */}
          <div className='w-100 text-center' style={{ height: '8rem'}}>
            <img
              className="img-fluid rounded d-block w-100 h-100"
              loading='lazy'
              src={Logo}
              alt="Our company logo"
           />
          </div>
          <div className='m-2 p-1 text-center text-capitalize'>
            <h3>contact us</h3>
          </div>
        {/* name input */}
        <Form.Group className="mb-3" controlId="formBasicName" style={{ textAlign: 'left'}}>
          <Form.Label>Name:</Form.Label>
          <Form.Control type="text" name='name' onChange={handleChange} placeholder="Enter your full name" />
        </Form.Group>
        {/* address input */}
        <Form.Group className="mb-3" controlId="formBasicEmail" style={{ textAlign: 'left'}}>
          <Form.Label>Email:</Form.Label>
          <Form.Control type="email" name='email' onChange={handleChange} placeholder="Enter your email address" />
        </Form.Group>
        {/* phone input */}
        <Form.Group controlId="formBasicPhone" className="mb-3" style={{ textAlign: 'left'}}>
          <Form.Label>Phone:</Form.Label>
          <Form.Control type="text" name='phone' onChange={handleChange} placeholder="Enter your phone number" />
        </Form.Group>
        {/* message textarea */}
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1" style={{ textAlign: 'left'}}>
          <Form.Label>Message:</Form.Label>
          <Form.Control as="textarea" name='message' onChange={handleChange} rows={3} placeholder='Enter your message'/>
        </Form.Group>
        {/* file input */}
        <Form.Group controlId="formFileMultiple" className="mb-3" style={{ textAlign: 'left'}}>
          <Form.Label>Choose File:</Form.Label>
          <Form.Control 
            type="file" 
            name="file"
            accept="image/*"  
            onChange={handleFileChange} />
        </Form.Group>
        {/* user feedback */}
        <Form.Group className='text-center my-2'>
          <Form.Text className="text-warning my-2 py-2 p-3" style={{
            fontSize: '1.25rem'
          }}>
            { feedback !== ""? feedback: ""}
          </Form.Text>
        </Form.Group>
        {/* submit contact btn */}
        <div className='text-center mb-3'>
          <Button variant="warning" 
            type="submit" 
            className='m-1 p-2 w-100 text-uppercase' 
            onClick={handleSubmit} disabled={IsFormValid()}>
            Submit
          </Button>
        </div>
      </Form>
    );
}

const Contact = () => {
    return (
        <div 
          className='hero py-5'
          style={{ display: 'relative'}}>
            <div style={{ maxWidth: '45rem' }}>
                <ContactForm />
            </div>
        </div>
    )
}

export default Contact;