import { About } from '../pages/About'
import { Appeal } from '../pages/Appeal'
import { Home } from '../pages/Home'
import { Login } from '../pages/Login'
import { Private } from '../pages/Private'
import { Account } from '../pages/Account'
import { Policy } from '../pages/Policy'
import { Process } from '../pages/Process'
import { Complaint } from '../pages/Complaint'
import { Condition } from '../pages/Condition'

// user types & dashboards
import Admin from '../pages/Admin'
import Landlord from '../pages/Landlord'
import Motorist from '../pages/Motorist'

// user imports
import UserManager from '../private/UserManager';

// vehicle imports
import VehicleManager from '../private/VehicleManager';
import MyVehicle from '../private/VehicleManager/view';
import CarEdit from '../private/VehicleManager/edit';
import { VehicleForm } from '../private/VehicleManager/Form';

// payment imports
import Payment from '../pages/Payment';
import PaymentManager from '../private/PaymentManager';

// contact import modules
import Contact from '../pages/Contact';
import ContactManager from '../private/ContactManager';
import {NewsletterForm} from '../private/ContactManager/News';

// pcn import modules
import NoticeManager from '../private/NoticeManager';
import { NoticeForm } from '../private/NoticeManager/Form';

// appeal import modules
import AppealManager from '../private/AppealManager';

// carpark import modules
import { CarparkForm } from '../private/CarparkManager/Form';


export const nav = [
    /************************* company services **************************/
    { path: "/", name: "Home", element: <Home />, isMenu: true, isPrivate: false, img: 'pi-home'},
    { path: "/about", name: "FQA", element: <About />, isMenu: true, isPrivate: false, img: 'pi-question-circle'},
    { path: "/contact", name: "Contact", element: <Contact />, isMenu: true, isPrivate: false, img: 'pi-envelope'},
    { path: "/appeal", name: "Appeal", element: <Appeal />, isMenu: true, isPrivate: false, img: 'pi-info-circle'},
    { path: "/payment", name: "Payment", element: <Payment />, isMenu: true, isPrivate: false, img: 'pi-credit-card'},
    { path: "/login", name: "Login", element: <Login />, isMenu: false, isPrivate: false, img: 'pi-sign-in'},
    { path: "/policy", name: "Privacy Policy", element: <Policy />, isMenu: false, isPrivate: false, img: 'pi-info-circle'},
    { path: "/condition", name: "Terms & Conditions", element: <Condition />, isMenu: false, isPrivate: false, img: 'pi-info-circle'},
    { path: "/process", name: "Appeal Process", element: <Process />, isMenu: false, isPrivate: false, img: 'pi-info-circle'},
    { path: "/complaint", name: "Complaint Process", element: <Complaint />, isMenu: false, isPrivate: false, img: 'pi-info-circle'},
    /************************* client services **************************/
    { path: "/private", name: "Dashboard", element: <Private />, isMenu: false, isPrivate: true},
    { path: "/account", name: "Account", element: <Account />, isMenu: false, isPrivate: true},
    /*** admin routes ***/
    { path: "/account/admin", name: "Dashboard", element: <Admin/>},
    { path: "/account/admin/newsletter", name: "Newsletter", element: <NewsletterForm />},
    /*** landlord routes ***/
    { path: "/account/landlord", name: "Dashboard", element: <Landlord/>},
    /*** motorist routes ***/
    { path: "/account/motorist", name: "Dashboard", element: <Motorist/>},
    /*** vehicle management ***/
    { path: "/account/vehicle", name: "Vehicles", element: <VehicleManager/> },
    { path: "/account/vehicle/:vehicleId", name: "View", element: <MyVehicle/>},
    { path: "/account/vehicle/:vehicleId/edit ", name: "Edit", element: <CarEdit />},
    { path: "/account/vehicle/:vehicleId/delete", name: "Delete", element: <h1>Delete Vehicle</h1>},
    { path: "/account/admin/vehicle", name: "Add Vehicle", element: <VehicleForm/> },
    /*** PCN managemet ***/
    { path: "/account/notice", name: "PCN", element: <NoticeManager />},
    { path: "/account/notice/:noticeId", name: "View", element: <h1>View Single PCN</h1>},
    { path: "/account/notice/:noticeId/delete", name: "Delete", element: <h1>Delete Notice</h1>},
    { path: "/account/admin/notice", name: "Edit", element: <NoticeForm />},
    /*** Appeal Management ***/
    { path: "/account/appeal", name: "Appeal", element: <AppealManager />},
    { path: "/account/appeal/:appealId", name: "View", element: <h1>View Single Appeal</h1>},
    { path: "/account/appeal/:appealId/edit", name: "Edit", element: <h1>Edit Appeal</h1>},
    { path: "/account/appeal/:appealId/delete", name: "Delete", element: <h1>Delete Appeal</h1>},
    /*** Carpark Management ***/
    { path: "/account/carpark", name: "Carpark", element: <h1>All Carparks</h1>},
    { path: "/account/carpark/:carparkId", name: "View", element: <h1>View Single Carpak</h1>},
    { path: "/account/carpark/:carparkId/edit", name: "Edit", element: <h1>Edit Carpark</h1>},
    { path: "/account/carpark/:carparkId/delete", name: "Delete", element: <h1>Delete Carpark</h1>},
    { path: "/account/admin/carpark", name: "Add Carpark", element: <CarparkForm />},
    /*** Payments Management ***/
    { path: "/account/payment", name: "Payment", element: <PaymentManager />},
    { path: "/account/payment/:paymentId", name: "View", element: <h1>View Single Payment</h1>},
    { path: "/account/payment/:paymentId/edit", name: "View", element: <h1>Edit Payment</h1>},
    { path: "/account/payment/:paymentId/delete", name: "Delete", element: <h1>Delete Payment</h1>},
    /*** User Management ***/
    { path: "/account/user", name: "User", element: <UserManager />},
    { path: "/account/user/:userId", name: "View", element: <h1>View Single user</h1>},
    { path: "/account/user/:userId/edit", name: "View", element: <h1>Edit user</h1>},
    { path: "/account/user/:userId/delete", name: "Delete", element: <h1>Delete user</h1>},
    /*** Contact Management ***/
    { path: "/account/contact", name: "contact", element: <ContactManager />},
    { path: "/account/contact/:contactId", name: "View", element: <h1>View Single contact</h1>},
    { path: "/account/contact/:contactId/edit", name: "View", element: <h1>Edit contact</h1>},
    { path: "/account/contact/:contactId/delete", name: "Delete", element: <h1>Delete contact</h1>},
]