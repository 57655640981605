import * as React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

function createData(id, firstname, lastname, email, phone, type, file) {
  return {
    id,
    firstname,
    lastname,
    email,
    phone,
    type,
    file: {
        type: "",
        data: [],
    }
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.firstname} &nbsp; {row.lastname}
        </TableCell>
        <TableCell align="right">{row.email}</TableCell>
        <TableCell align="right">
          {
            {
              'admin': <span className='bg-primary p-2' style={{ width: '15rem'}}>Admin</span>,
              'landlord': <span className='bg-success p-2' style={{ width: '15rem'}}>Landlord</span>,
              'client': <span className='bg-danger p-2' style={{ width: '15rem'}}>Client</span>,
            }[row.type]
          }
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                File
              </Typography>
              <Table size="small" aria-label="file details">
                <TableHead>
                  <TableRow>
                    <TableCell>Email</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell>File</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.phone}</TableCell>
                    <TableCell component="th" scope="row">
                      {row.file ? row.file.data : 'N/A'}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number.isRequired,
    firstname: PropTypes.string.isRequired,
    lastname: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    file: PropTypes.shape({
      type: PropTypes.string,
      data: PropTypes.string,
    }),
  }).isRequired,
};

const rows = [
  createData(1, '', '', '', '', '', ''),
];

export default function CollapsibleTable({notice}) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Full Name</TableCell>
            <TableCell align="right">Email&nbsp;(@)</TableCell>
            <TableCell align="right">Type&nbsp;(Type of User)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {notice !== null | notice.length <= 0 ? notice.map((row)=>(
            <Row key={row.id} row={row} />
          )) : (
            <p>Parking Charge Notice database empty!</p>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}