import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from "../../../assets/wlogo.svg";

function MyForm(){
    const [ formData, setFormData ] = useState({
      message: "",
      text: "",
      to: [],
    });
    const [users, setUsers] = useState([]);
    const [ feedback, setFeedback ] = useState("");
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();
  
    const handleChange = (e) =>{
      const { name, value } = e.target;
      setFormData({...formData, [name]: value});
    }
  
    const severAPI = async () =>{
      axios.post(`/api/user/news`, formData)
        .then((response)=>{
            console.log(`Newsletter Submit Response: ${response}`)
            setFeedback("Newsletter has sent!");
            setTimeout(()=>{
              navigate('/account/contact')
            }, 5000)
        })
        .catch((err)=>{
            setFeedback("Problem with network! Try again later.");
            setError('Failed to send newsletter.');
            setTimeout(()=>{
              setFeedback("");
            }, 5000)
        })
    }

    useEffect(() => {
      const fetchUsers = async () => {
        try {
          const storedUser = localStorage.getItem('user');
          if (!storedUser) throw new Error('No user found in localStorage');
      
          const parsedUser = JSON.parse(storedUser);
          if (!parsedUser.token) throw new Error('No token found for user');
      
          const data = await axios.get('/api/user/news', {
            headers: {
              'Authorization': `Bearer ${parsedUser.token}`
            }
          });
          setUsers(data);
        } catch (err) {
          console.error('Error fetching users:', err);
          setError('Failed to fetch users.');
        }
      };
  
      fetchUsers();
    }, []);
    
    const handleSubmit = (e) =>{
      e.preventDefault();
      severAPI();

      setSuccess(true);
      setError('');
    }
  
    return (
      <Form className="my-2 py-3 px-2 rounded form-container" style={{ background: '#31393C'}}>
        <div className='w-100 text-center' style={{ height: '8rem'}}>
            <img
              className="img-fluid rounded d-block w-100 h-100"
              loading='lazy'
              src={Logo}
              alt="Our company logo"
           />
        </div>
        {/* form title */}
        <div className='m-2 p-1 text-center'>
          <h5 className='mb-2'>Our Newsletter</h5>
        </div>
        {/* name input */}
        <Form.Group controlId="formBasicText" className="mb-3" style={{ textAlign: 'left'}}>
          <Form.Label>Subject:</Form.Label>
          <Form.Control type="text" name='subject' onChange={handleChange} placeholder="Enter newsletter subject" />
        </Form.Group>
        {/* message textarea */}
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1" style={{ textAlign: 'left'}}>
          <Form.Label>Message:</Form.Label>
          <Form.Control 
            as="textarea" 
            name='text' 
            onChange={handleChange} 
            rows={3} placeholder='Enter newsletter message'/>
        </Form.Group>
        {/* user feedback */}
        <Form.Group className='text-center m-2'>
          <Form.Text className="text-warning" style={{
            fontSize: '1.25rem'
          }}>
            { feedback !== ""? feedback: ""}
          </Form.Text>
        </Form.Group>
        {/* submit contact btn */}
        <div className='text-center'>
          <Button variant="warning" type="submit" className='w-100' onClick={handleSubmit}>
            Share
          </Button>
          {success && <p style={{ color: 'green' }}>Newsletter sent successfully!</p>}
          {error && <p style={{ color: 'red' }}>{error}</p>}
        </div>
        <div className='text-center'>
          <h5>Users</h5>
          <ul>
            {users.map((user) => (
              <li key={user.id}>{user.email}</li>
            ))}
          </ul>
        </div>
      </Form>
    );
}

export const NewsletterForm = () =>{
    return(
        <div className='hero' style={{ height: 'auto'}}>
            <div>
                <MyForm />        
            </div>
        </div>
    )
}