import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from "../../../assets/wlogo.svg";

function MyForm(){
    const [ formData, setFormData ] = useState({
      name: "",
      model: "",
      color: "",
      VRPNumber: "", 
      file: null
    });
    const [ feedback, setFeedback ] = useState("");
    const navigate = useNavigate();
  
    const handleChange = (e) =>{
      const { name, value } = e.target;
      setFormData({...formData, [name]: value});
    }

    const handleFileChange = (e) =>{
        setFormData({
          ...formData, 
          file: e.target.files[0] 
        });
    }
  
    const severAPI = async () =>{
      axios.post(`/api/property`, formData)
        .then((response)=>{
            console.log(`Carpark Submit Response: ${response}`)
            setFeedback("Carpark has been added!");
            setTimeout(()=>{
              navigate('/account/carpark')
            }, 5000)
        })
        .catch((err)=>{
            setFeedback("Problem with network! Try again later.");
            setTimeout(()=>{
              setFeedback("");
            }, 5000)
        })
    }
    
    const handleSubmit = (e) =>{
      e.preventDefault();
      if(formData.name == "" | formData.VRPNumber == "" | formData.model == "") return setFeedback("Please fill All inputs!");
      severAPI();
    }
  
    return (
      <Form className="my-2 py-3 px-2 rounded form-container" style={{ background: '#31393C'}}>
        <div className='w-100 text-center' style={{ height: '8rem'}}>
            <img
              className="img-fluid rounded d-block w-100 h-100"
              loading='lazy'
              src={Logo}
              alt="Our company logo"
           />
        </div>
        {/* form title */}
        <div className='m-2 p-1 text-center'>
          <h5 className='mb-2'>Register Carpark</h5>
        </div>
        {/* name input */}
        <Form.Group controlId="formBasicText" className="mb-3" style={{ textAlign: 'left'}}>
          <Form.Label>Name:</Form.Label>
          <Form.Control type="text" name='name' onChange={handleChange} placeholder="Enter name of carpark" />
        </Form.Group>
        {/* email input */}
        <Form.Group controlId="formBasicEmail" className="mb-3" style={{ textAlign: 'left'}}>
          <Form.Label>address:</Form.Label>
          <Form.Control type="text" name='address' onChange={handleChange} placeholder="Enter carpark location" />
        </Form.Group>
        {/* phone input */}
        <Form.Group controlId="formBasicPhone" className="mb-3" style={{ textAlign: 'left'}}>
          <Form.Label>owner's email</Form.Label>
          <Form.Control type="email" name="owner" onChange={handleChange} placeholder="Enter landlord's email" />
        </Form.Group>
        {/* file input */}
        <Form.Group controlId="formFileMultiple" className="mb-3" style={{ textAlign: 'left'}}>
          <Form.Label>Choose File:</Form.Label>
          <Form.Control 
            type="file" 
            name='file' 
            onChange={handleFileChange} multiple />
        </Form.Group>
        {/* user feedback */}
        <Form.Group className='text-center m-2'>
          <Form.Text className="text-warning" style={{
            fontSize: '1.25rem'
          }}>
            { feedback !== ""? feedback: ""}
          </Form.Text>
        </Form.Group>
        {/* submit contact btn */}
        <div className='text-center'>
          <Button variant="warning" type="submit" className='w-100' onClick={handleSubmit}>
            Add
          </Button>
        </div>
      </Form>
    );
}

export const CarparkForm = () =>{
    return(
        <div className='hero' style={{ height: 'auto'}}>
            <div>
                <MyForm />        
            </div>
        </div>
    )
}