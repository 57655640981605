import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Navbar from 'react-bootstrap/Navbar';
import CollapsibleTable from "./Table.";
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

function ServiceLinks() {
  const navigate = useNavigate();

  const handleNavigate = (link) =>{
    navigate(link);
  }

  return (
    <>
      <div className="w-100 flex justify-content-center align-items-center my-3 py-2">
        <Button 
          variant="secondary" 
          size="lg"
          className="m-3"
          onClick={()=>handleNavigate('/account/admin/vehicle')}>
          User Vehicle
        </Button>{' '}
        <Button 
          variant="secondary" 
          size="lg"
          className="m-3"
          onClick={()=>handleNavigate('/account/landlord/location')}>
          User Carpark
        </Button>{' '}
      </div>
    </>
  );
}

function SearchNoticeComponent(){
    const [ data, setData ] = useState([]);
    const [ searchItem, setSearchItem ] = useState('');
    const [ feedback, setFeedBack ] = useState(false);
    const navigate = useNavigate();
  
    const handleNavigate = (link) =>{
      navigate(link);
    }
  
    const serverAPI = async () =>{
      await axios.get(`/api/user/search/${searchItem}`)
        .then((response)=>{
          if(!response.data) return 
          setData(response.data);
          setFeedBack(true);
        })
        .catch((err)=>{
          console.log(err.message);
        })
    }
  
    const handleChange = async (e) =>{
      setFeedBack(false);
      setSearchItem(e.target.value);
    }
  
    const handleSearch = (e) =>{
      e.preventDefault();
      if(searchItem == '')return
      serverAPI();
    }
  
    return(
      <div className="row">
        {/* search component */}
        <Navbar expand="lg" className="bg-body-tertiary">
          <Container fluid>
            <Navbar.Collapse id="navbarScroll">
              <Form className="d-flex">
                <Form.Control
                  type="search"
                  placeholder="Search"
                  className="me-2"
                  aria-label="search"
                  onChange={handleChange}
                  aria-placeholder="user email"
                />
                <Button variant="outline-warning" onClick={handleSearch}>Search</Button>
              </Form>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        {/* search result component */}
        <Card>
          <Card.Body>
            {feedback ? <CollapsibleTable notice={data} /> : ""}
            <Card.Title>User Management Panel</Card.Title>
            <Card.Text>
                Welcome to the Users Management Panel. This interface allows you to efficiently manage user accounts, including creating, updating, and deactivating profiles. Please ensure you follow all security protocols and guidelines to maintain the integrity of user data. If you need any assistance, our support team is available to help. Thank you for your commitment to effective user management.
            </Card.Text>
            <Button 
                variant="warning" 
                onClick={()=>handleNavigate('/account/admin/user')}>Add Admin User</Button>&nbsp;&nbsp;&nbsp;
            <Button 
                variant="secondary" 
                onClick={()=>handleNavigate('/account')}>Go To Dashboard</Button>
          </Card.Body>
        </Card>
      </div>
    )
}

const UserManager = () => {
    const [userData, setUserData] = useState([]);

    const userAPI = async () => {
      try {
        const storedUser = localStorage.getItem('user');
        if (!storedUser) throw new Error('No user found in localStorage');
    
        const parsedUser = JSON.parse(storedUser);
        if (!parsedUser.token) throw new Error('No token found for user');
    
        const response = await axios.get('/api/user/fetch', {
          headers: {
            'Authorization': `Bearer ${parsedUser.token}`
          }
        });
        setUserData(response.data);
      } catch (err) {
        console.error("Error fetching user data: ", err.message);
      }
    };
    
    useEffect(()=>{
      userAPI();
    },[])

    return(
        <div>
            <SearchNoticeComponent />
            <ServiceLinks />
            <h4 className="text-center text-uppercase mb-2">User Records</h4>
            <CollapsibleTable notice={userData}/>
        </div>
    )
}

export default UserManager;