import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from "../../../assets/wlogo.svg";

function ParkingChargeNoticeForm(){
    const [ formData, setFormData ] = useState({
      name: "",
      email: "",
      phone: "",
      amount: 50, 
      role: "", 
      address: "", 
      location: "", 
      vehicle: "", 
      arrivingTime: "", 
      arrivingDate: "", 
      departureTime: "", 
      departureDate: "",
      file: null
    });
    const [ feedback, setFeedback ] = useState("");
    const navigate = useNavigate();
  
    const handleChange = (e) =>{
      const { name, value } = e.target;
      setFormData({...formData, [name]: value});
    }

    const handleFileChange = (e) =>{
      setFormData({
        ...formData, 
        file: e.target.files[0] 
      });
    }
  
    const noticeAPI = async () =>{
      axios.post(`/api/notice`, formData)
        .then((response)=>{
            console.log(`PCN Submit Response: ${response}`)
            setFeedback("Your PCN has been created!");
            setTimeout(()=>{
              navigate('/account/notice')
            }, 5000)
        })
        .catch((err)=>{
            setFeedback("Problem with network! Try again later.");
            setTimeout(()=>{
              setFeedback("");
            }, 5000)
        })
    }
    
    const handleSubmit = (e) =>{
      e.preventDefault();
      if(formData.amount == "" | formData.vehicle == "" | formData.role == "") return setFeedback("Please fill All inputs!");
      noticeAPI();
    }
  
    return (
      <Form className="my-2 py-3 px-2 rounded form-container" style={{ background: '#31393C'}}>
        <div className='w-100 text-center' style={{ height: '8rem'}}>
            <img
              className="img-fluid rounded d-block w-100 h-100"
              loading='lazy'
              src={Logo}
              alt="Our company logo"
           />
        </div>
        {/* form title */}
        <div className='m-2 p-1 text-center'>
          <h5 className='mb-2'>Parking Charge Notice (PCN)</h5>
        </div>
        {/* name input */}
        <Form.Group controlId="formBasicText" className="mb-3" style={{ textAlign: 'left'}}>
          <Form.Label>Name:</Form.Label>
          <Form.Control type="text" name='name' onChange={handleChange} placeholder="Enter client name" />
        </Form.Group>
        {/* email input */}
        <Form.Group controlId="formBasicEmail" className="mb-3" style={{ textAlign: 'left'}}>
          <Form.Label>Email:</Form.Label>
          <Form.Control type="email" name='email' onChange={handleChange} placeholder="Enter client email" />
        </Form.Group>
        {/* phone input */}
        <Form.Group controlId="formBasicPhone" className="mb-3" style={{ textAlign: 'left'}}>
          <Form.Label>Phone:</Form.Label>
          <Form.Control type="text" name='phone' onChange={handleChange} placeholder="Enter client phone number" />
        </Form.Group>
        {/* amount input */}
        <Form.Group controlId="formBasicAmount" className="mb-3" style={{ textAlign: 'left'}}>
          <Form.Label>Charge:</Form.Label>
          <Form.Control type="number" name='amount' onChange={handleChange} placeholder="Enter client PCN charge fee" />
        </Form.Group>
        {/* address input */}
        <Form.Group controlId="formBasicAddress" className="mb-3" style={{ textAlign: 'left'}}>
          <Form.Label>Address:</Form.Label>
          <Form.Control type="text" name='address' onChange={handleChange} placeholder="Enter client physical address" />
        </Form.Group>
        {/* location input */}
        <Form.Group controlId="formBasicLocation" className="mb-3" style={{ textAlign: 'left'}}>
          <Form.Label>Location:</Form.Label>
          <Form.Control type="text" name='location' onChange={handleChange} placeholder="Enter client carpark location" />
        </Form.Group>
        {/* role input */}
        <Form.Group className='mb-3' style={{ textAlign: 'left'}}>
          <Form.Label>I am the:</Form.Label>
          <Form.Select aria-label="select role input" name='role' onChange={handleChange}>
            <option value="driver">driver</option>
            <option value="keeper">keeper</option>
            <option value="hirer">hirer</option>
          </Form.Select>
        </Form.Group>
        {/*  Vehicle Registration */}
        <Form.Group controlId="formBasicVehicle" className="mb-3" style={{ textAlign: 'left'}}>
          <Form.Label>Vehicle:</Form.Label>
          <Form.Control type="text" name='vehicle' onChange={handleChange} placeholder="Enter your vehicle registration number" />
        </Form.Group>
        {/* Arriving & Departing Time */}
        <Form.Group controlId="formBasicTime" className="mb-3 d-flex justify-content-between align-items-center">
          <div>
            <Form.Label>Arrived Time:</Form.Label>
            <Form.Control type="time" name="arrivingTime" onChange={handleChange} placeholder="Enter vehicle arrived time" />
          </div>
          <div>
            <Form.Label>Departed Time:</Form.Label>
            <Form.Control type="time" name="departureTime" onChange={handleChange} placeholder="Enter vehicle departed time" />
          </div>
        </Form.Group>
        {/* Arriving & Departing Date */}
        <Form.Group controlId="formBasicDate" className="mb-3 d-flex justify-content-between align-items-center">
          <div>
            <Form.Label>Arrived Date:</Form.Label>
            <Form.Control type="date" name="arrivingDate" onChange={handleChange} placeholder="Enter vehicle arrived date" />
          </div>
          <div>
            <Form.Label>Departed Date:</Form.Label>
            <Form.Control type="date" name="departureDate" onChange={handleChange} placeholder="Enter vehicle departed date" />
          </div>
        </Form.Group>
        {/* file input */}
        <Form.Group controlId="formFileMultiple" className="mb-3" style={{ textAlign: 'left'}}>
          <Form.Label>Choose File:</Form.Label>
          <Form.Control type="file" name='file' onChange={handleFileChange} multiple />
        </Form.Group>
        {/* user feedback */}
        <Form.Group className='text-center m-2'>
          <Form.Text className="text-warning" style={{
            fontSize: '1.25rem'
          }}>
            { feedback !== ""? feedback: ""}
          </Form.Text>
        </Form.Group>
        {/* submit contact btn */}
        <div className='text-center'>
          <Button variant="warning" type="submit" className='w-100' onClick={handleSubmit}>
            Send PCN
          </Button>
        </div>
      </Form>
    );
}

export const NoticeForm = () =>{
    return(
        <div className='hero' style={{ height: 'auto'}}>
            <div>
                <ParkingChargeNoticeForm />        
            </div>
        </div>
    )
}