import { Button, Form } from 'react-bootstrap';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

function VehicleForm() {
    const [formData, setFormData] = useState({
        registration: '',
        model: '',
        color: '',
        owner: '',
    });
    const [feedback, setFeedback] = useState('');
    const [isLogging, setIsLogging] = useState(false);
    const navigate = useNavigate();
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };
  
    const serverAPI = async () => {
      try {
        
        setFormData({ 
            registration: '',
            model: '',
            color: '',
            owner: ''
        });
      } catch (error) {
        setFormData({ 
            registration: '',
            model: '',
            color: '',
            owner: ''
        }); 
      } finally {
        setIsLogging(false);
      }
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      serverAPI();
    };
  
    return (
      <Form className="m-1 p-1 form-container" onSubmit={handleSubmit}>
        {/* registrationk input */}
        <Form.Group className="mb-2" controlId="formBasicEmail">
          <Form.Label>Number Plate:</Form.Label>
          <Form.Control
            type="text"
            name="registration"
            value={formData.registration}
            onChange={handleChange}
            placeholder="...vehicle number plate..."
            className='text-center'
            required
          />
        </Form.Group>
        {/* model input */}
        <Form.Group className="mb-2" controlId="formBasicPassword">
          <Form.Label>Model:</Form.Label>
          <Form.Control
            type="text"
            name="model"
            value={formData.model}
            onChange={handleChange}
            placeholder="...vehicle model..."
            className='text-center'
            required
          />
        </Form.Group>
        {/* color input */}
        <Form.Group className="mb-2" controlId="formBasicPassword">
          <Form.Label>Color:</Form.Label>
          <Form.Control
            type="text"
            name="color"
            value={formData.color}
            onChange={handleChange}
            placeholder="...vehicle color..."
            className='text-center'
            required
          />
        </Form.Group>
        {/* owner input */}
        <Form.Group className="mb-2" controlId="formBasicPassword">
          <Form.Label>Owner:</Form.Label>
          <Form.Control
            type="text"
            name="owner"
            value={formData.owner}
            onChange={handleChange}
            placeholder="...vehicle owner..."
            className='text-center'
            required
          />
        </Form.Group>
        {/*  Submit */}
        <div className="text-center">
          <Button
            variant="warning"
            className="w-100"
            type="submit"
            disabled={isLogging}
          >
            {isLogging ? 'Submitting...' : 'Submit'}
          </Button>
        </div>
      </Form>
    );
}

const CarEdit = () =>{
    return(
        <div>
            <h5>Edit your contact</h5>
            <VehicleForm/>
        </div>
    )
}

export default CarEdit; 