import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CollapsibleTable from "./NoticeManager/Table";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import axios from "axios";
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Navbar from 'react-bootstrap/Navbar';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import CameraEnhanceIcon from '@mui/icons-material/CameraEnhance';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import AddBoxIcon from '@mui/icons-material/AddBox';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { PieChart } from "./Pie";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

const ServiceComponents = () =>{
  const [ noticeData, setNoticeData ] = useState([]);
  const navigate = useNavigate();
  const data = [
    {
      id: 'Approved',
      label: 'Approved',
      value: 40,
      color: 'hsl(120, 70%, 50%)',
    },
    {
      id: 'Pending',
      label: 'Pending',
      value: 35,
      color: 'hsl(60, 70%, 50%)',
    },
    {
      id: 'Rejected',
      label: 'Rejected',
      value: 25,
      color: 'hsl(0, 70%, 50%)',
    },
  ];
  
    const handleNavigate = (link) =>{
      navigate(link);
    }

  return(
    <div className="container-fluid">
      {/* notices */}
      <div className="row mb-3">
        {/* parking charge notice notifications */}
        <div className="col d-flex justify-content-center align-items-center mt-3 mb-3">
          <div className="d-flex justify-content-center align-items-center p-2" style={{
              width: '16rem',
              height: '8rem',
              boxShadow: 'inset .05rem .05rem .15rem',
              borderRadius: '.25rem'
            }}>
              <div className="col text-center p-3">
                <CameraEnhanceIcon sx={{ fontSize: '4rem', transition: 'all 200ms', cursor: 'pointer', '&:hover':{ transform: 'scale(1.3)'}}}/>
                <p>PCNs</p>
              </div>
              <div className="col" style={{ fontSize: '2rem'}}>#{'02'}</div>
          </div>
        </div>
        {/* appeal notifications */}
        <div className="col d-flex justify-content-center align-items-center mt-3 mb-3">
          <div className="d-flex justify-content-center align-items-center" style={{
              width: '16rem',
              height: '8rem',
              boxShadow: '.1rem .1rem .25rem',
              borderRadius: '.25rem'
            }}>
              <div className="col text-center p-3">
                <AutoModeIcon sx={{ fontSize: '4rem', transition: 'all 200ms', cursor: 'pointer', '&:hover':{ transform: 'rotateZ(270deg)'}}}/>
                <p>Appeals</p>
              </div>
              <div className="col" style={{ fontSize: '2rem'}}>#{'05'}</div>
            </div>
        </div>
        {/* payment notifications */}
        <div className="col d-flex justify-content-center align-items-center mt-3 mb-3">
            <div className="d-flex justify-content-center align-items-center p-2" style={{
                width: '16rem',
                height: '8rem',
                boxShadow: 'inset .05rem .05rem .15rem',
                borderRadius: '.25rem'
              }}>
              <div className="col text-center p-3">
                <ShoppingCartCheckoutIcon sx={{ fontSize: '4rem', transition: 'all 200ms', cursor: 'pointer', '&:hover':{ transform: 'translateX(2rem)'}}}/>
                Payments
              </div>
              <div className="col" style={{ fontSize: '2rem'}}>#{'03'}</div>
            </div>
        </div>
      </div>
      {/* services */}
      <div className="row mb-3">
        <div className="col d-flex flex-column justify-content-center align-items-center" 
          style={{ height: '18rem'}}>
          <div className="row">
            <Button 
                variant="warning"
                className="text-uppercase mb-2" 
                style={{ width: '15rem', height: '2.8rem'}}
                onClick={()=>handleNavigate('/account/landlord')}><AddBoxIcon /> Make Appeal</Button>
          </div>
          <div className="row">
            <Button 
                variant="dark"
                className="text-uppercase mb-2" 
                style={{ width: '15rem', height: '2.8rem'}}
                onClick={()=>handleNavigate('/account/landlord')}> <VisibilityIcon sx={{ color: '#fff'}}/> View Appeal</Button>
          </div>
          <div className="row">
            <Button 
                variant="warning"
                className="text-uppercase mb-2" 
                style={{ width: '15rem', height: '2.8rem'}}
                onClick={()=>handleNavigate('/account/landlord')}><AddBoxIcon /> Make Payment</Button>
          </div>
          <div className="row">
            <Button 
                variant="dark"
                className="text-uppercase mb-2" 
                style={{ width: '15rem', height: '2.8rem'}}
                onClick={()=>handleNavigate('/account/landlord')}> <VisibilityIcon sx={{ color: '#fff'}}/> View Payment</Button>
          </div>
        </div>
        <div className="col p-2" style={{ height: '18rem'}}>
            <h5 className="text-center text-uppercase p-2 m-1">Status of your Appeals</h5>
            <PieChart data={data}/>
        </div>
      </div>
      {/* records */}
      <div className="row mb-3">
        <div className="col">
          <h5 className="text-center p-2 m-1">Parking Charge Notice Records</h5>
          <CollapsibleTable notice={noticeData} />
        </div>
        {/* <div className="col">
          <h5 className="text-center p-2 m-1">Appeal Records</h5>
          <div>
            
          </div>
        </div>
        <div className="col">
          <h5 className="text-center p-2 m-1">Payment Records</h5>
        </div> */}
      </div>
    </div>
  )
}

function SearchNoticeComponent(){
    const [ noticeData, setNoticeData ] = useState([]);
    const [ searchItem, setSearchItem ] = useState('');
    const [ feedback, setFeedBack ] = useState(false);
    const navigate = useNavigate();
  
    const handleNavigate = (link) =>{
      navigate(link);
    }
  
    const noticeAPI = async () =>{
      await axios.get(`/api/notice/search/${searchItem}`)
        .then((response)=>{
          if(!response.data) return 
          setNoticeData(response.data);
          setFeedBack(true);
        })
        .catch((err)=>{
          console.log(err.message);
        })
    }
  
    const handleChange = async (e) =>{
      setFeedBack(false);
      setSearchItem(e.target.value);
    }
  
    const handleSearch = (e) =>{
      e.preventDefault();
      if(searchItem == '')return
      noticeAPI();
    }
  
    return(
      <div className="row">
        {/* search component */}
        <Navbar expand="lg" className="bg-body-tertiary">
          <Container fluid>
            <Navbar.Collapse id="navbarScroll">
              <Form className="d-flex">
                <Form.Control
                  type="search"
                  placeholder="Search"
                  className="me-2"
                  aria-label="Search"
                  onChange={handleChange}
                  aria-placeholder="vehicle registration"
                />
                <Button variant="outline-warning" onClick={handleSearch}>Search</Button>
              </Form>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        {/* search result component */}
        <Card>
          <Card.Body>
            {feedback ? <CollapsibleTable notice={noticeData} /> : ""}
            <Card.Title style={{ fontWeight: '700', fontSize: '2rem'}}>Motorists</Card.Title>
            <Card.Text style={{ maxWidth: '40rem', lineHeight: '1.5'}}>
                Welcome to the Motorists Service Portal. Here, you can efficiently manage and resolve any issues you may have concern about. Please ensure you follow all instructions carefully to address your notices promptly. If you need assistance, our customer support team is available to help. Thank you for using our service.
            </Card.Text>
            <Button 
                variant="secondary"
                className="mb-3" 
                onClick={()=>handleNavigate('/account/landlord')}>Go To Dashboard</Button>
            {/* dashboard details */}
            <ServiceComponents />
          </Card.Body>
        </Card>
      </div>
    )
}

export const ClientDB = () =>{
    return(
        <div className="container">   
            <SearchNoticeComponent />
        </div>
    )
}